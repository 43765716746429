import styled from "@emotion/styled"

const Figcaption = styled.figcaption`
  color: red,
  font-size: 14px;
  text-align: center;
  width: 100%;
  padding-top: 6px;
`

export default Figcaption
