import React from "react"
import { Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import Figcaption from "./Figcaption"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  // Is root page or index (/2, /3 etc)
  const isIndex = isRootPath || !isNaN(location.pathname.replace(/\//g, ""))
  let header

  if (isIndex) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  const shortcodes = {
    figcaption: Figcaption,
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>
        <MDXProvider components={shortcodes}>{children}</MDXProvider>
      </main>
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer>
    </div>
  )
}

export default Layout
